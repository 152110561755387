import React, { useState } from "react";
import DataTable from "../../components/pageParts/table";
import ActionCell from "../../components/pageParts/ActionCell";
import {
  Check,
  FilterIcon,
  X,
  FileDown,
  Download,
  CircleDollarSign,
  Users,
} from "lucide-react";
import { ArrowUpDown } from "lucide-react";
import { Button } from "../../components/ui/button";
import { serviceUrl, serviceUrlFile } from "../../config";
import {
  useKarnetyQuery,
  useDeleteKarnetyMutation,
  useChangePaidMutation,
} from "../../services/karnetyApi";

import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogDescription,
  DialogTitle,
} from "../../components/ui/dialog";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "../../components/ui/use-toast";
import { Tooltip } from "../../components/ui/tooltip";
import {
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import { DatePicker } from "../../components/ui/datePicker";
import { CheckboxWithLabel } from "../../components/ui/checkboxWithLabel";
import { CheckboxWithLabelInline } from "../../components/ui/checkboxWithLabelInline";
import { UiSelectMulti } from "../../components/ui/UiSelectMulit";
import { InputWithLabel } from "../../components/ui/inputWithLabel";
import { Cookies } from "react-cookie";
import { da } from "date-fns/locale";
import { set } from "lodash";
import { sub } from "date-fns";
const KarnetList = () => {
  const navigate = useNavigate();
  const title = undefined;

  const { data } = useKarnetyQuery(undefined);
  const [deleteKarnet, response] = useDeleteKarnetyMutation();
  const [changePaid] = useChangePaidMutation();

  const karnety = {
    KF: "Pełny",
    KD: "Debiut",
    KM: "Miesięczny",
    KK: "Kontynuacja",
  };

  const d = data?.map((item) => {
    return {
      ...item,
      karnetName: karnety[item.typ],
      objectId: item._id.$oid,
    };
  });

  const headerCells = [
    {
      accessorKey: "objectId",
      header: "objectId",
      visibilty: false,
      fancyName: "objectId",
    },
    {
      accessorKey: "karnetCode",
      header: "Kod",
      fancyName: "Kod",
    },

    {
      accessorKey: "karnetName",
      header: "Typ karnetu",
      fancyName: "Typ karnetu",
    },
    {
      accessorKey: "imie",
      header: "Imię",
      fancyName: "Imię",
    },
    {
      accessorKey: "nazwisko",
      header: "Nazwisko",
      fancyName: "Nazwisko",
    },
    {
      accessorKey: "inv_number",
      header: "Faktura",
      fancyName: "Faktura",
    },

    {
      accessorKey: "paid",
      visibilty: false,
      facyName: "paid",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Zapłacono
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }: any) => {
        return (
          <div
            className="cursor-pointer"
            onClick={() => {
              changePaid({
                id: row.original._id.$oid,
                paid: !row.original.paid,
              });
            }}
            onKeyDown={() => {}}
            onKeyUp={() => {}}
          >
            {row.original.paid ? (
              <Check className="text-green-500" />
            ) : (
              <X className="text-red-500" />
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "block",
      visibilty: false,
      facyName: "Blokada",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Blokada
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }: any) => {
        return (
          <div>
            {row.original.block ? (
              <Check className="text-green-500" />
            ) : (
              <X className="text-red-500" />
            )}
          </div>
        );
      },
    },
    {
      enableSorting: true,
      accessorKey: "zweryfikowany",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Zweryfikowany
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      facyName: "Zweryfikowany",
      visibilty: false,
      cell: ({ row }: any) => {
        return (
          <div>
            {row.original.zweryfikowany ? (
              <Check className="text-green-500" />
            ) : (
              <X className="text-red-500" />
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "action",
      allwaysVisible: true,
      fancyName: "objectId",
      header: () => {
        <div className="flex justify-end">Akcje</div>;
      },

      cell: ({ row }: any) => {
        // console.log(row);
        return (
          <div className="justify-end flex text-[0.em]">
            <ActionCell
              deleteAction={deleteKarnet}
              editLink={"karnety/edit"}
              row={row}
            />
          </div>
        );
      },
    },
  ];

  const filters = [
    {
      field: "karnetCode",
      label: "Kod karnetu",
    },
    {
      field: "nazwisko",
      label: "Nazwisko",
    },
    {
      field: "paid",
      label: "Zapłacone",
      type: "bool",
    },
    {
      field: "block",
      label: "Zablokowany",
      type: "bool",
    },
    {
      field: "zweryfikowany",
      label: "Zweryfikowany",
      type: "bool",
    },
  ];

  const [registerDateFrom, setRegisterDateFrom] = useState<Date | null>(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [registerDateTo, setRegisterDateTo] = useState<Date | null>(new Date());

  const [invoiceMonth, setInvoiceMont] = useState<string>("");
  const [invoiceYear, setInvoiceYear] = useState<string>(
    new Date().getFullYear().toString()
  );
  const [invoicesReportError, setInvoicesReportError] = useState<
    string | null
  >();

  const [subsType, setSubsType] = useState<string[]>([]);
  const [subsPaid, setSubsPaid] = useState<boolean>(false);
  const [subsUnpaid, setSubsUnpaid] = useState<boolean>(false);

  const [subError, setSubError] = useState<string | null>();

  const getSubsReport = async () => {
    setSubError(null);
    const payload = {
      types: subsType,
      paid: subsPaid,
      unpaid: subsUnpaid,
      registerDateFrom: registerDateFrom,
      registerDateTo: registerDateTo,
    };

    const response = await fetch(`${serviceUrl}/report/subs/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${new Cookies().get("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "raport_subskrybentow.csv";
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      setSubError("Nie udało się pobrać raportu");
    }
  };

  const getInvoicesReport = async () => {
    setInvoicesReportError(null);

    if (invoiceMonth && invoiceYear) {
      const month = Number(invoiceMonth);
      if (Number.isNaN(month) || month < 1 || month > 12) {
        setInvoicesReportError("Nieprawidłowy miesiąc");
        return;
      }

      const year = Number.parseInt(invoiceYear);
      if (
        Number.isNaN(year) ||
        year < 1900 ||
        year > new Date().getFullYear()
      ) {
        setInvoicesReportError("Nieprawidłowy rok");
        return;
      }

      const response = await fetch(
        `${serviceUrl}/report/invoices/${month}/${year}/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${new Cookies().get("token")}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `raport_faktur_${month}_${year}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        setInvoicesReportError("Nie udało się pobrać raportu");
      }

      // Rest of your code here
    } else {
      setInvoicesReportError("Month and year are required!");
    }
  };

  return (
    <div>
      {d && (
        <DataTable
          columns={headerCells}
          data={d}
          label="Karnety"
          filters={filters}
          tableName="karnety"
          itemPerPage={100}
          extraActions={
            <div>
              <Dialog>
                <DialogTrigger>
                  <Button variant={"ghost"}>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Users size={"15px"} />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Raport subskrybentów</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Raport subskrybentów</DialogTitle>
                    <DialogDescription className="py-2">
                      <>
                        <div>
                          <UiSelectMulti
                            label="Typ karnetu"
                            options={[
                              {
                                label: "Pełny",
                                value: "KF",
                              },
                              {
                                label: "Debiut",
                                value: "KD",
                              },
                              {
                                label: "Miesięczny",
                                value: "KM",
                              },
                              {
                                label: "Kontynuacja",
                                value: "KK",
                              },
                            ]}
                            value={subsType}
                            onChange={(e) => {
                              setSubsType(e);
                            }}
                          />
                        </div>
                        <div className="flex">
                          <CheckboxWithLabelInline
                            label="Tylko opłacone"
                            onCheckedChange={(val) => {
                              setSubsPaid(val as boolean);
                              setSubsUnpaid(false);
                            }}
                            checked={subsPaid || false}
                          />
                        </div>
                        <div className="flex">
                          <CheckboxWithLabelInline
                            label="Tylko nieopłacone"
                            onCheckedChange={(val) => {
                              setSubsUnpaid(val as boolean);
                              setSubsPaid(false);
                            }}
                            checked={subsUnpaid || false}
                          />
                        </div>
                        <div className="mt-5">
                          Zarejstrowani w terminie
                          <div className="grid grid-cols-2 gap-5 mt-5">
                            <div className="flex gap-5 items-center">
                              <DatePicker
                                label="Od"
                                selected={registerDateFrom}
                                value={registerDateFrom}
                                onChange={(date) => setRegisterDateFrom(date)}
                              />
                            </div>
                            <div className="flex gap-5 items-center">
                              <DatePicker
                                label="Do"
                                selected={registerDateTo}
                                value={registerDateTo}
                                onChange={(date) => setRegisterDateTo(date)}
                              />
                            </div>
                          </div>
                          <div>
                            {subError && (
                              <div className="text-red-500 mt-5">
                                {subError}
                              </div>
                            )}
                          </div>
                          <div className="flex justify-end mt-5">
                            <Button
                              onClick={() => {
                                getSubsReport();
                              }}
                            >
                              Pobierz
                            </Button>
                          </div>
                        </div>
                      </>
                    </DialogDescription>
                  </DialogHeader>
                </DialogContent>
              </Dialog>

              <Dialog>
                <DialogTrigger>
                  <Button variant={"ghost"}>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <CircleDollarSign size={"15px"} />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Raport faktur</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Raport faktur</DialogTitle>
                    <DialogDescription>
                      <div className="grid grid-cols-2 gap-5 mt-5">
                        <div className="flex gap-5 items-center">
                          <InputWithLabel
                            label="Miesiąc"
                            onChange={(e) => {
                              setInvoiceMont(e.target.value);
                            }}
                            value={invoiceMonth}
                          />
                        </div>
                        <div className="flex gap-5 items-center">
                          <InputWithLabel
                            label="Rok"
                            onChange={(e) => {
                              setInvoiceYear(e.target.value);
                            }}
                            value={invoiceYear}
                          />
                        </div>
                      </div>
                      <div>
                        {invoicesReportError && (
                          <div className="text-red-500 mt-5">
                            {invoicesReportError}
                          </div>
                        )}
                      </div>
                      <div className="flex justify-end mt-5">
                        <Button
                          onClick={() => {
                            getInvoicesReport();
                          }}
                        >
                          Pobierz
                        </Button>
                      </div>
                    </DialogDescription>
                  </DialogHeader>
                </DialogContent>
              </Dialog>
            </div>
          }
        />
      )}
    </div>
  );
};
export default KarnetList;

import { cloneDeep } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import EnhancedFrom, {
  ImperativeHandle,
} from "../../components/form/EnhancedFrom";
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardHeader } from "../../components/ui/card";

import {
  useKarnetyQuery,
  useAddKarnetyMutation,
  useUpdateKarnetyMutation,
  useDeleteKarnetyMutation,
  useCreateInvoiceMutation,
  useChangeIssueDateMutation,
} from "../../services/karnetyApi";

import { useStatusesQuery } from "../../services/statusesApi";
import { serviceUrl, serviceUrlFile } from "../../config";
import { UiSelect } from "../../components/ui/UiSelect";
import { InputWithLabel } from "../../components/ui/inputWithLabel";
import { UiButton } from "../../components/ui/UiButton";
import { CheckboxWithLabel } from "../../components/ui/checkboxWithLabel";

import { usePageListByTypeQuery } from "../../services/pageListApi";
import { Cookies } from "react-cookie";
import { toast } from "../../components/ui/use-toast";

import { DatePicker } from "../../components/ui/datePicker";

type KarnetProps = {};

const Karnet = (props: KarnetProps) => {
  const params = useParams();

  const { data } = useKarnetyQuery(params.id);
  const [dataCopy, setDataCopy] = React.useState<any>();
  const { refetch, data: datax } = usePageListByTypeQuery(
    "660eded18de3c655f68c9b9d"
  );

  const email = datax?.map((x: any) => {
    if (x?.statuses[0].includes(dataCopy?.typ)) {
      return { value: x._id["$oid"], label: x.title };
    }
  });

  React.useEffect(() => {
    if (data && data[0]) {
      setDataCopy(data[0]);
    }
  }, [data]);
  const { data: statuses } = useStatusesQuery(null);

  const [updateKarnet] = useUpdateKarnetyMutation();
  const [createInvoice] = useCreateInvoiceMutation();
  const [changeIssueDate] = useChangeIssueDateMutation();

  const [currentEmail, setCurrentEmail] = React.useState<any>(null);

  const save = () => {};

  const formRef = React.useRef<ImperativeHandle>(null);

  const dict = {
    imie: "imię i nazwisko",
    nazwisko: "nazwisko",
    ulica: "ulica",
    kod_pocztowy: "kod pocztowy",
    email: "email",
    telefon: "telefon",
  };
  const token = new Cookies().get("token");

  // const createInvoice = async () => {
  //   const res = await fetch(
  //     `${serviceUrl}/karnet/invoice/create/${dataCopy?._id["$oid"]}/`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "text/json",
  //         "Access-Control-Allow-Origin": "*",
  //         authorization: `Bearer ${token}`,
  //       },
  //     }
  //   );

  //   const data = await res.json();
  // };

  const sendInvoice = async () => {
    const res = await fetch(
      `${serviceUrl}/karnet/invoice/email/${dataCopy?._id["$oid"]}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "text/json",
          "Access-Control-Allow-Origin": "*",
          authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await res.json();

    if (data.status === "ok") {
      toast({
        variant: "success",
        title: "Super!",
        description: "Email został wysłany!",
      });
    } else {
      toast({
        variant: "destructive",
        title: "Bład!",
        description: "Niestety email nie został wysłany!",
      });
    }
  };

  const getPdf = async () => {
    const res = await fetch(
      `${serviceUrl}/karnet/invoice/show/${dataCopy?._id["$oid"]}/`,
      {
        method: "GET",

        headers: {
          "Content-Type": "application/pdf",
          "Access-Control-Allow-Origin": "*",
          authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.blob();

    const url = window.URL.createObjectURL(data);
    const a = document.createElement("a"); // Create an <a> element
    a.href = url; // Set the href property
    a.download = "faktura.pdf"; // Set the download property
    a.click(); // Trigger a click event
    window.URL.revokeObjectURL(url);
  };

  const sendStatusMail = async ({ id, emailId }: any) => {
    const res = await fetch(`${serviceUrl}/karnety/sendMail/`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: id,
        emailId: emailId,
      }),
    });
    const data = await res.json();
    if (data.status === "ok") {
      toast({
        variant: "success",
        title: "Super!",
        description: "Email został wysłany!",
      });
    } else {
      toast({
        variant: "destructive",
        title: "Bład!",
        description: "Niestety email nie został wysłany!",
      });
    }
  };

  return (
    <Card>
      <CardContent>
        {dataCopy && (
          <>
            <CardHeader>
              Karnet - {dataCopy?.karnet} ({dataCopy?.typ})
            </CardHeader>

            <strong>Podsumowanie</strong>
            <div className="grid grid-cols-2">
              <div>
                <div className="py-5">
                  <div className="flex gap-5">
                    <div>data zamówienia:</div>
                    <div>
                      {dataCopy?.createdAt["$date"] &&
                        new Date(
                          dataCopy?.createdAt["$date"]
                        ).toLocaleDateString()}
                    </div>
                  </div>
                  <div className="flex gap-5">
                    <div>Kod karnetu:</div>
                    <div>{dataCopy?.karnetCode}</div>
                  </div>
                  {dataCopy?.month && (
                    <div className="flex gap-5">
                      <div>Wykupiony miesiąc:</div>
                      <div>{dataCopy?.month}</div>
                    </div>
                  )}
                  <div className="flex gap-5">
                    <CheckboxWithLabel
                      label="Zablokowany"
                      checked={dataCopy?.block}
                      onCheckedChange={(val) => {
                        updateKarnet({
                          id: dataCopy?._id["$oid"],
                          rest: { block: val },
                        });
                      }}
                    />
                    <CheckboxWithLabel
                      label="Zapłacone"
                      checked={dataCopy?.paid}
                      onCheckedChange={(val) => {
                        updateKarnet({
                          id: dataCopy?._id["$oid"],
                          rest: { paid: val },
                        });
                      }}
                    />
                    {["KK", "KD"].includes(dataCopy?.typ) && (
                      <div className="flex gap-5">
                        <div>
                          <CheckboxWithLabel
                            label="Zweryfikowany"
                            checked={dataCopy?.zweryfikowany}
                            onCheckedChange={(val) => {
                              updateKarnet({
                                id: dataCopy?._id["$oid"],
                                rest: { zweryfikowany: val },
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className="flex items-end gap-2 max-w-[400px]">
                    <UiSelect
                      label="Wyślij email"
                      options={email}
                      onChange={(value) => {
                        setCurrentEmail(value);
                      }}
                    />

                    <Button
                      className="min-w-[200px] "
                      onClick={() => {
                        sendStatusMail({
                          id: dataCopy?._id["$oid"],
                          emailId: currentEmail,
                        });
                      }}
                    >
                      Wyślij email
                    </Button>
                  </div>
                </div>
              </div>
              <div>
                <strong>Faktura {dataCopy?.inv_number}</strong>
                <div className="grid gap-2 ">
                  {/* <div className="flex gap-6 items-center">
                    <DatePicker
                      label="Data zapłaty"
                      value={
                        dataCopy?.invoice?.paymentDate?.$date
                          ? dataCopy?.invoice?.paymentDate?.$date
                          : dataCopy.createdAt.$date
                      }
                      onChange={(value) => {
                        changePaidDate({
                          id: dataCopy?._id["$oid"],
                          paymentDate: value,
                        });
                      }}
                    />
                  </div> */}
                  {!dataCopy?.invoice?.number && (
                    <div className="flex gap-2 items-center">
                      <DatePicker
                        label="Data wystawienia/zapłaty"
                        value={
                          dataCopy?.invoice?.issueDate?.$date
                            ? dataCopy?.invoice?.issueDate?.$date
                            : dataCopy.createdAt.$date
                        }
                        onChange={(value) => {
                          changeIssueDate({
                            id: dataCopy?._id["$oid"],
                            issueDate: value,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="mt-5 flex gap-2">
                  {dataCopy?.invoice?.number && (
                    <>
                      <Button
                        onClick={() => {
                          getPdf();
                        }}
                      >
                        Pokaż fakturę
                      </Button>
                      <Button
                        onClick={() => {
                          sendInvoice();
                        }}
                      >
                        Wyślij fakturę
                      </Button>
                    </>
                  )}
                  {!dataCopy?.invoice?.number && (
                    <Button
                      onClick={() => {
                        createInvoice({
                          id: dataCopy?._id["$oid"],
                        });
                      }}
                    >
                      Wystaw fakturę
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <hr className="my-5 block" />
            <strong className="py-5 block">Dane Abonenta</strong>

            <div>
              <div className="grid md:grid-cols-2 gap-5">
                <InputWithLabel
                  label="Imię"
                  value={dataCopy?.imie}
                  onChange={(e) => {
                    setDataCopy({
                      ...dataCopy,
                      imie: e.target.value,
                    });
                  }}
                />
                <InputWithLabel
                  label="Nazwisko"
                  value={dataCopy?.nazwisko}
                  onChange={(e) => {
                    setDataCopy({
                      ...dataCopy,
                      nazwisko: e.target.value,
                    });
                  }}
                />
                <InputWithLabel
                  label="Email"
                  value={dataCopy?.email}
                  onChange={(e) => {
                    setDataCopy({
                      ...dataCopy,
                      email: e.target.value,
                    });
                  }}
                />
                <InputWithLabel
                  label="Telefon"
                  value={dataCopy?.telefon}
                  onChange={(e) => {
                    setDataCopy({
                      ...dataCopy,
                      telefon: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="grid md:grid-cols-3 gap-5 mt-5">
                <InputWithLabel
                  label="Ulica"
                  value={dataCopy?.ulica}
                  onChange={(e) => {
                    setDataCopy({
                      ...dataCopy,
                      ulica: e.target.value,
                    });
                  }}
                />

                <InputWithLabel
                  label="Kod pocztowy"
                  value={dataCopy?.kod_pocztowy}
                  onChange={(e) => {
                    setDataCopy({
                      ...dataCopy,
                      kod_pocztowy: e.target.value,
                    });
                  }}
                />
                <InputWithLabel
                  label="Miasto"
                  value={dataCopy?.miasto}
                  onChange={(e) => {
                    setDataCopy({
                      ...dataCopy,
                      miasto: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <hr className="my-5 block" />
            <div className="flex justify-end">
              <UiButton
                label="Zapisz"
                onClick={() => {
                  updateKarnet({
                    id: dataCopy?._id["$oid"],
                    rest: dataCopy,
                  });
                }}
              ></UiButton>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};
export default Karnet;

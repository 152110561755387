// ==============================|| THEME CONFIG  ||============================== //

type configType = { [key: string]: any }

const config: configType = {
    defaultPath: '/dashboard/default',
    fontFamily: `'Plus Jakarta Sans', sans-serif`,
    i18n: 'pl',
    miniDrawer: false,
    container: true,
    mode: 'light',
    presetColor: 'default',
    themeDirection: 'ltr'
};

const isLocal = false
const urlx = isLocal ? "http://localhost:8000/" : "https://backtenis.koniar3.usermd.net/"


export default config;
export const drawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';
export const url = urlx
export const serviceUrl = `${url}api`
export const serviceUrlFile = urlx + "/media/serveInFly/";
export const loginCover = 'tenis.jpg'

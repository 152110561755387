import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";

import React from "react";
import { Button } from "../../components/ui/button";
import { ChevronRight, ChevronLeft, Filter, Cog } from "lucide-react";
import { InputWithLabel } from "../ui/inputWithLabel";
import { CheckboxWithLabel } from "../ui/checkboxWithLabel";
import { UiSelect } from "../ui/UiSelect";
import { Card } from "../ui/card";
import { useState } from "react";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  filters?: any;
  label?: string;
  extraActions?: any;
  tableName?: string;
  itemPerPage?: number;
}

function DataTable<TData, TValue>({
  columns,
  data,
  filters,
  label,
  extraActions,
  tableName,
  itemPerPage = 10,
}: DataTableProps<TData, TValue>) {
  // const table = useReactTable({
  //   data,
  //   columns,
  //   getCoreRowModel: getCoreRowModel(),
  // })
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const [pagination, setPagination] = useState({
    pageIndex: 0, // initial page index
    pageSize: itemPerPage, // default page size
  });

  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({ objectId: false });
  const table = useReactTable({
    data,
    //@ts-ignore
    columns,

    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,

    state: {
      columnVisibility,
      sorting,
      pagination,
      columnFilters,
    },
    onPaginationChange: setPagination,
  });

  const [isFilterSet, setIsFilterSet] = React.useState(false);

  React.useEffect(() => {
    const columns = JSON.parse(
      localStorage.getItem(`${tableName}_filters`) as string
    );

    setIsFilterSet(columns ? Object.keys(columns).length > 0 : false);

    if (columns) {
      // columns["objectId"] = false;
      // table?.setColumnVisibility(columns);
      const columnsKeys = Object.keys(columns);
      for (let key of columnsKeys) {
        if (
          columns[key] != undefined ||
          columns[key] != null ||
          columns[key] != ""
        ) {
          table?.getColumn(key)?.setFilterValue(columns[key]);
        }
      }
    }
  }, []);

  const pagesToDisplay = 5;

  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <h1 className="font-bold uppercase text-2xl">
          {label ? label : "brakujący label"}
        </h1>

        <div className="flex items-center">
          <div> {extraActions}</div>
          {filters && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant={isFilterSet ? "circle" : "ghost"}>
                  <Filter size={"15px"} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <div className="px-5 py-5">
                  <h2 className="text-xl font-bold">Szukaj</h2>
                  <div className="grid grid-cols-3 gap-5 flex-wrap ">
                    {filters &&
                      filters.map((el: any, index: number) => {
                        if (el?.type != "bool") {
                          return (
                            <div
                              className="flex items-center gap-2"
                              key={index}
                            >
                              <InputWithLabel
                                value={
                                  (table
                                    .getColumn(el.field)
                                    ?.getFilterValue() as string) ?? ""
                                }
                                onChange={(event) => {
                                  if (
                                    event.target.value == "" ||
                                    !event.target.value
                                  ) {
                                    //delete from localstorage
                                    const filters = JSON.parse(
                                      localStorage.getItem(
                                        `${tableName}_filters`
                                      ) as string
                                    );
                                    delete filters[el.field];
                                    localStorage.setItem(
                                      `${tableName}_filters`,
                                      JSON.stringify(filters)
                                    );
                                    const columns = filters;
                                    setIsFilterSet(
                                      columns
                                        ? Object.keys(columns).length > 0
                                        : false
                                    );
                                  } else {
                                    localStorage.setItem(
                                      `${tableName}_filters`,
                                      JSON.stringify({
                                        ...JSON.parse(
                                          localStorage.getItem(
                                            `${tableName}_filters`
                                          ) as string
                                        ),
                                        [el.field]: event.target.value,
                                      })
                                    );
                                    const columns = JSON.stringify({
                                      ...JSON.parse(
                                        localStorage.getItem(
                                          `${tableName}_filters`
                                        ) as string
                                      ),
                                      [el.field]: event.target.value,
                                    });
                                    setIsFilterSet(
                                      columns
                                        ? Object.keys(columns).length > 0
                                        : false
                                    );
                                  }

                                  table
                                    .getColumn(el.field)
                                    ?.setFilterValue(event.target.value);
                                }}
                                label={el.label}
                                className="max-w-sm"
                              />
                            </div>
                          );
                        }
                        if (el?.type == "bool") {
                          return (
                            <div
                              className="flex items-center gap-2"
                              key={index}
                            >
                              <UiSelect
                                label={el.label}
                                options={[
                                  { label: "Wszystkie", value: null },
                                  { label: "Tak", value: true },
                                  { label: "Nie", value: false },
                                ]}
                                onChange={(value) => {
                                  //delete if empty
                                  if (value == null) {
                                    const filters = JSON.parse(
                                      localStorage.getItem(
                                        `${tableName}_filters`
                                      ) as string
                                    );
                                    delete filters[el.field];
                                    localStorage.setItem(
                                      `${tableName}_filters`,
                                      JSON.stringify(filters)
                                    );

                                    const columns = filters;
                                    setIsFilterSet(
                                      columns
                                        ? Object.keys(columns).length > 0
                                        : false
                                    );
                                  } else {
                                    localStorage.setItem(
                                      `${tableName}_filters`,
                                      JSON.stringify({
                                        ...JSON.parse(
                                          localStorage.getItem(
                                            `${tableName}_filters`
                                          ) as string
                                        ),
                                        [el.field]: value,
                                      })
                                    );

                                    const columns = JSON.stringify({
                                      ...JSON.parse(
                                        localStorage.getItem(
                                          `${tableName}_filters`
                                        ) as string
                                      ),
                                      [el.field]: value,
                                    });
                                    setIsFilterSet(
                                      columns
                                        ? Object.keys(columns).length > 0
                                        : false
                                    );
                                  }
                                  table
                                    .getColumn(el.field)
                                    ?.setFilterValue(value);
                                }}
                                value={
                                  (table
                                    .getColumn(el.field)
                                    ?.getFilterValue() as string) ?? ""
                                }
                              />
                              {/* <InputWithLabel
                                value={
                                  (table
                                    .getColumn(el.field)
                                    ?.getFilterValue() as string) ?? ""
                                }
                                onChange={(event) => {
                                  table
                                    .getColumn(el.field)
                                    ?.setFilterValue(event.target.value);
                                }}
                                label={el.label}
                                className="max-w-sm"
                              /> */}
                            </div>
                          );
                        }
                        return null;
                      })}
                  </div>
                </div>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant={"ghost"}>
                <Cog size={"15px"} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {table
                .getAllColumns()
                .filter(
                  //@ts-ignore
                  (column) =>
                    column.getCanHide() &&
                    //@ts-ignore
                    column?.columnDef?.visibilty == undefined &&
                    //@ts-ignore
                    column.columnDef?.allwaysVisible == undefined
                )
                .map((column) => {
                  console.log(column);
                  // @ts-ignore
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) => {
                        tableName &&
                          localStorage.setItem(
                            `${tableName}_visibilty`,
                            JSON.stringify({
                              ...JSON.parse(
                                localStorage.getItem(
                                  `${tableName}_visibilty`
                                ) as string
                              ),
                              [column.id]: value,
                            })
                          );
                        column.toggleVisibility(!!value);
                      }}
                    >
                      {
                        //@ts-ignore
                        column.columnDef.fancyName
                      }
                    </DropdownMenuCheckboxItem>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <Card>
        <div className="rounded-xl">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    Brak danych
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <div className="grid justify-end px-5 py-2">
            <div className="flex items-center justify-end space-x-2 py-4">
              <Button
                variant="outline"
                size="sm"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <ChevronLeft size={"15px"} />
              </Button>

              {[...Array(table?.getPageCount())].map((el, index) => {
                const startPage = Math.max(
                  (table?.options.state.pagination?.pageIndex || 0) -
                    pagesToDisplay +
                    1,
                  0
                );
                const endPage = Math.min(
                  startPage + pagesToDisplay,
                  table?.getPageCount()
                );
                if (index >= startPage && index < endPage + 1) {
                  return (
                    <Button
                      key={index}
                      variant="outline"
                      size="sm"
                      onClick={() => table.setPageIndex(index)}
                      disabled={
                        table?.options.state.pagination?.pageIndex == index
                      }
                    >
                      {index + 1}
                    </Button>
                  );
                }
              })}

              {table?.getPageCount() > pagesToDisplay && <span>...</span>}

              {table?.getPageCount() > pagesToDisplay &&
                [...Array(table?.getPageCount())].map((el, index) => {
                  if (index > table?.getPageCount() - pagesToDisplay) {
                    return (
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => table.setPageIndex(index)}
                        disabled={
                          table?.options.state.pagination?.pageIndex == index
                        }
                      >
                        {index + 1}
                      </Button>
                    );
                  }
                })}

              <Button
                variant="outline"
                size="sm"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <ChevronRight size={"15px"} />
              </Button>
            </div>
            {/* <span className="flex justify-end text-xs">

ilość stron {table?.options.state.pagination?.pageIndex || 0 +1 }  z {table.getPageCount()-1}

        </span> */}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default DataTable;
